export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "cn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
  "afterSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TicketCenter"])},
  "afterSalesQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKET"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "linkItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrip"])},
  "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
  "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
  "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NextStep"])},
  "addStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Step"])},
  "begEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StartEditing"])},
  "endEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "addType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AddType"])},
  "addChildType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AddChildType"])},
  "addChildQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AddChildQuestion"])},
  "addQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AddQuestion"])},
  "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved"])},
  "unsolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unresolved"])},
  "unsolvedAndChatNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unresolved And Chat Now"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "deleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DeleteQuestion"])},
  "changeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ChangeType"])},
  "deleteType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DeleteType"])},
  "returnKnowledgeBase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KnowledgeBase"])},
  "viewUserQuestionStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ViewUserQuestionStep"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "itemManage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ProductManage"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change"])},
  "needStaffService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the current directory is not found related issues, seeking staff service(login firstly)"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login"])},
  "reconnecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconnecting..."])},
  "identifying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifying..."])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
  "homeHintTit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need Help? Here's How:"])},
  "homeHint1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Check out our topic directory on the left side for FAQs."])},
  "homeHint2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. If that doesn't solve your issue or you can't find the category, just hit 'Chat now'."])},
  "homeHint3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. We're here to help via live chat (16/7)."])},
  "productFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Filter"])},
  "needHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat now"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "alterFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alter Failed"])},
  "alterFailedHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to the local data is not the latest, cause the failure of modified, please refresh the page to retry."])},
  "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Success"])},
  "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Failed"])},
  "loginNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Login First"])},
  "chatNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Now"])},
  "unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unread"])},
  "unresolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unresolved"])},
  "backToLastStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back to last step"])},
  "allItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Products"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity"])},
  "ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKET"])},
  "questionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QuestionType"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
  "faultItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fault Product"])},
  "syncItemInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["syncProductInfo"])},
  "viewOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ViewOrder"])},
  "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Num"])},
  "orderNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OrderNum"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OrderStatus"])},
  "payStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayStatus"])},
  "shipStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ShipStatus"])},
  "getOrderFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "gettingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getting"])},
  "getOrderEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])},
  "unsolvedSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure this ticket had not resolved?"])},
  "solvedSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure this ticket had resolved?"])},
  "uploadProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Progress"])},
  "checkAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALL"])},
  "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resolved"])},
  "noReply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no-reply"])},
  "mixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "unrated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unrated"])},
  "recall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recall"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load More"])},
  "inviteScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your score"])},
  "chatAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Again"])},
  "inputSuggest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input your suggestions..."])},
  "sendPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a message..."])},
  "CustomerServiceOnlineStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer service online status"])},
  "goToChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to chat"])},
  "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to login"])},
  "createTicketFistly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please create a ticket firstly"])},
  "suggestCheckBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other,please leave your suggestions:"])},
  "yourComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Comments: "])},
  "yourSuggest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Suggestions: "])},
  "createTicketSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the ticket successfully, and will go to chatting window, if can not enter that window, check the setting of your browser."])},
  "createTicketFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Ticket failed,don’t repeat to create tickets within a short time. You can click the “TICKET” to obtain manual customer service."])},
  "inputQuestionHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Question..."])},
  "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rate:"])},
  "serviceTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Time"])},
  "workTimeHint1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear user/buyer, we supply service in our working time from Monday to Friday,9:00AM-6:00PM local China(UTC/GMT +8.00)"])},
  "workTimeHint2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's"])},
  "workTimeHint3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here now.Please leave your message, we will reply as soon as possible when we are online. Many thanks."])},
  "workTimeHint4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you very much."])},
  "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "modifySuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Data successed!"])},
  "modifyFaild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Data failed,please wait to remodify a moment."])},
  "submitSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Successful"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping"])},
  "collocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Cockpit"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service"])},
  "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "curLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "userCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User center"])},
  "myOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My order"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About WINWING"])},
  "clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of service"])},
  "problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "winwing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WINWING"])},
  "backTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back top"])},
  "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to WW news"])},
  "moblieHomeHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the relevant problems from the above catalog"])},
  "knowledgeAndSupportTicketSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge & Support Ticket System"])},
  "knowledgeCenterSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowlege Center System"])},
  "findSolutionInKnowledgeCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["find solution in knowlege center"])},
  "supoortTicketSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Ticket System"])},
  "leadLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in and apply an tickets for manual support"])},
  "IfCantGetSolutionInKnowledgeCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(problem is peculiar and can not find help in knowledge center)"])},
  "IfNoSolutionIsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your problem is peculiar and can not find help in knowledge Center System, we will guide you Sign in and apply an tickets for manual support. <br>Our \"Knowlege Center System\" supply you accurate/detailed info and figure out problem more quickly, meanwhile the \"Support Ticket System\" will help us improve service quality."])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "subscribedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Successfully"])},
  "subscribedFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Failed,Please contact us."])},
  "maxUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload file size suggested that less than 100M"])},
  "hotas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotas"])},
  "combo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combo"])},
  "controlPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control Panel"])},
  "gripAndHandle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grip & Handle"])},
  "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
  "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessories"])},
  "failUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload failed! File server maintenance, please try again later."])},
  "ContactCustomerSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Customer Support (16/7)"])},
  "LiveChatisopenMondaytoSunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Chat is open Monday to Sunday"])},
  "Onlinetimeperiod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon -Sun,8:00 AM -12:00 PM, 1:00 PM -12:30 AM (UTC+8)"])},
  "Onlinetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Mon - Sun, 8:00 AM - 12.00 PM, 1.00 PM . 12:30 AM, UTC+8)"])}
}